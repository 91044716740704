import React from "react"
import Title from "./Title"
import Project from "./Project"
import { Link } from "gatsby"

const Projects = ({ projects, title, subheading, showLink }) => {
  return (
    <section className="project-wrapper">
      <div className="wrapper">
        <Title title={title} />
        <div className="subheading">{subheading}</div>
        <div className="project-container">
          {projects.map((project, index) => {
            return <Project key={project.id} index={index} {...project} />
          })}
        </div>
      </div>

      <div className="center mb-1">
        {showLink && (
          <Link to="/projects" className="btn">
            All Projects
          </Link>
        )}
      </div>
    </section>
  )
}

export default Projects
