import React from "react"
import Image from "gatsby-image"

import { FaGithubSquare, FaExternalLinkAlt } from "react-icons/fa"

const Project = ({ cover, title, description, link, git, techs }) => {
  return (
    <div className="project-card" title={title}>
      <div className="container">
        <Image fluid={cover.fluid} className="project-image" />

        {link && (
          <a
            href={link}
            className="link"
            rel="noopener noreferrer"
            target="_blank"
          >
            <FaExternalLinkAlt className="link-icon" />
          </a>
        )}
      </div>
      <div className="card-footer">
        <div className="info">
          <div
            className="title"
            style={{ marginTop: `${link ? "1.5em" : ""}` }}
          >
            {title}
          </div>
          <div className="description">{description.description}</div>
        </div>
        <div className="links">
          {git && (
            <a
              className="git"
              href={git}
              rel="noopener noreferrer"
              target="_blank"
            >
              <FaGithubSquare size="1.4rem" />
            </a>
          )}

          <div className="techs">
            {techs.map((tech, id) => (
              <div key={id} className="chip">
                <span>{tech}</span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Project
